@if $toc {
  /* - Header___________________Site header */
} @else {
  /* Site header [component]
    ========================================================================== */

  .c-header {
    background-color: theme(default-bgc);

    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      //text-align: center;

      .o-wrapper {
        display: block;
      }
    }

    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      position: relative;
      z-index: 666; // Put menu above everything else
      padding-top: rem(12); // 12px
      background: linear-gradient(
        to bottom,
        theme(header-bgc),
        theme(header-bgc) #{rem(50)},
        theme(default-bgc) #{rem(50)}
      );
    }
  }

  .has-unfolded-header .c-header {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 667; // Place header in front sticky buttons
      height: 100vh;
      background-color: theme(default-bgc);
    }
  }

  .has-unfolded-header .c-header {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: flex;
      flex-direction: column;
    }
  }

  // Contact wrapper
  .c-header__contact,
  .c-header__shipping {
    display: block;
    font-size: rem(12); // 12px
    line-height: 1; // 12px

    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: none;

      .has-unfolded-header & {
        display: block;
      }
    }
  }

  .c-header-mobile__contact {
    position: absolute;
    bottom: 4.75rem;
    left: 1rem;
    right: 1rem;

    @include mq($from: S) {
      display: none;
    }
  }

  // Phone number
  .c-header__contact-phone {
    margin-left: rem(8); // 8px
    color: inherit;

    #{$interactions} {
      color: inherit;
    }

    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: block;
      margin: rem(12) 0 rem(32); // 12px 0 32px
    }
  }

  // Contact link
  .c-header__contact-mail {
    display: none;
  }

  .has-unfolded-header .c-header__contact-mail {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: block;
    }
  }

  /* Contact insert
  .c-header__contact {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      position: absolute;
      right: rem(16); // 16px
      bottom: rem(76); // 76px = 60 + 16 (= c-shortcut's height + 16px space)
      left: rem(16); // 16px
      display: none;
      text-align: left;
    }

    .has-unfolded-header & {
      // Targets mobiles in portrait mode, tablets, non-touch terminals that have a width of less than 50em in portrait mode and IE11 under 50em in portrait mode
      @media (hover: none) and (max-width: 49.99em) and (orientation: portrait),
        (hover: none) and (min-width: 50em),
        (hover: hover) and (max-width: 49.99em) and (orientation: portrait),
        (-ms-high-contrast: none) and (max-width: 49.99em) and (orientation: portrait) {
        display: block;
      }
    }
  }*/

  // Logo
  .c-header__logo {
    margin-top: rem(25); // 25px
    margin-bottom: rem(25); // 25px

    &:focus {
      outline: 1px dotted theme(default-outline);
    }

    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: inline-block;
      margin: rem(12) 0; // 12px 0
      height: rem(45); // 45px
    }

    .has-unfolded-header & {
      // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
      @media (hover: none),
        (hover: hover) and (max-width: 49.99em),
        (-ms-high-contrast: none) and (max-width: 49.99em) {
        display: none;
      }
    }
  }

  // Logo img
  .c-header__logo-img {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: inline-block;
      width: auto;
      height: rem(45); // 45px
    }

    // @include mq($until: M) {
    //   height: rem(45); // 36px
    // }
  }

  // Specific case
  // With sticky-basket-summary
  .has-sticky-basket-summary .c-header {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: none;
    }
  }

  // Shopping tunnel header
  .c-header--shopping-tunnel {
    padding-top: 0;
    text-align: center;
    background-image: none;
    border-bottom: 1px solid theme(default-bdc);

    // Logo
    .c-header__logo {
      // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
      @media (hover: none),
        (hover: hover) and (max-width: 49.99em),
        (-ms-high-contrast: none) and (max-width: 49.99em) {
        display: inline-block;
        width: auto;
        height: rem(36); // 36px
      }

      // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
      @media (hover: hover) and (min-width: 50em),
        (-ms-high-contrast: none) and (min-width: 50em) {
        display: block;
        margin: rem(12) auto; // 12px auto
      }
    }

    // Contact insert
    .c-header__contact {
      @include mq($until: M) {
        display: none;
      }

      @include mq($from: M) {
        position: absolute;
        top: rem(16); // 16px
        left: 0;
      }
    }
  }

  // Specific to custom events
  .c-info-banner {
    text-align: center;
    padding: 5px 10px;
    background-color: $coral-red;
    color: $white;

    a {
      color: $white;
      font-size: 1rem;
    }
  }
}
