.gdpr__cookie_consent-btn,
.gdpr__cookie_consent {
  bottom: 10px;
  left: 10px;
  position: fixed;
  z-index: 666;
}

.gdpr__cookie_consent-btn {
  border: none;
  background-color: transparent;

  img {
    width: 40px;
    height: 40px;
  }

  span {
    display: none;
  }
}

.gdpr__cookie_consent {
  background: #ffffff;
  border: 1px solid $marigold;
  padding: 15px;
  right: 15px;
  font-size: 14px;
  max-width: 500px;
  padding: 15px;
  position: fixed;
}

.gdpr__cookie_consent__text {
  img {
    width: 35px;
    height: 35px;
    display: inline;
  }

  img,
  h3 {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
}
.gdpr__cookie_consent__text,
.gdpr__cookie_consent__text p {
  margin-bottom: 15px;
}

@include mq($from: M) {
  .gdpr__cookie_consent-btn {
    bottom: 10px;
  }
}

@include mq($until: M) {
  .gdpr__cookie_consent-btn {
    bottom: 4rem;
  }
}

@include mq($until: S) {
  .gdpr__cookie_consent {
    top: 15px;
    bottom: unset;
  }
  .gdpr__cookie_consent-btn {
    top: 0;
    left: 0;
    bottom: unset;
    img {
      width: 50px;
      height: 50px;
    }
  }

  .gdpr__cookie_consent__buttons {
    display: flex;
    flex-direction: column;

    .c-btn {
      margin: 0 0 0.5rem;
    }
  }
}
