@if $toc {
  /* - Txt alignment____________Set text alignment (right or center) */
} @else {
  /* Set text alignment (right or center) [utility]
    ========================================================================== */

  // Center alignment
  [class].u-txt-center {
    display: block;
    text-align: center;
  }

  // Right alignment
  [class].u-txt-right {
    text-align: right;
  }


  // Responsive class
  @include mq($until: S) {
    [class].u-txt-center\@us {
      display: block;
      text-align: center;
    }
  }
}
