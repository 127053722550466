@if $toc {
  /* - Inline___________________Inline tags <abbr>, <del>, <ins>, <mark>, <small> and <strong> */
} @else {
  /* Default styles for abbr, del, ins, mark, small and strong [element]
   ========================================================================== */

  abbr[title],
  ins {
    text-decoration: none;
  }

  del,
  ins {
    display: inline-block;
    margin: 0 rem(2); // 2px
  }

  del {
    color: theme(del-txt);
    text-decoration: line-through;
  }

  mark {
    @include font($type: semibold, $family: $sans-serif);
    display: inline-block;
    padding: 0 em(6, 12); // 6px
    color: theme(default-txt);
    font-size: rem(12); // 12px
    line-height: lh(24, 12); // 24px
    font-weight: normal;
    letter-spacing: 0.4px;
    background-color: theme(mark-bgc);
  }

  small {
    display: inline-block; // Mandatory for line-height application
    color: theme(default-txt);
    font-weight: normal;
    font-size: rem(12); // 12px
    line-height: lh(18, 12); // 18px
  }

  small a {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  strong {
    font-weight: 600;
  }
}
